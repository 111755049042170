import webDesign from '../../../assets/webDesign.jpg';
import softwareDevelopment from '../../../assets/softwareDevelopment.jpg';
import socialMediaManagement from '../../../assets/socialMediaManagement.jpg';


const services = [
  {
    id: 'webDesign',
    tid: 'webDesign',
    descriptionTid: 'webDesignDescription',
    image: webDesign,
    muted: "fromScratch",
    leadTid: 'webDesignLead'
  },
  {
    id: 'softwareDevelopment',
    tid: 'softwareDevelopment',
    descriptionTid: 'softwareDevelopmentDescription',
    image: softwareDevelopment,
    muted: "softwareDevelopmentMuted",
    leadTid: 'softwareDevelopmentLead'
  },
  {
    id: 'socialMediaManagement',
    tid: 'socialMediaManagement',
    descriptionTid: 'socialMediaManagementDescription',
    image: socialMediaManagement,
    muted: "socialMediaManagementMuted",
    leadTid: 'socialMediaManagementLead'
  }
];

export default services;
