import React, { useState } from "react";
import { Form, Row, Button, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import Text from "../Text";

import manuPic from "../../assets/manu.jpg";
import maruPic from "../../assets/maru.jpg";
import alePic from "../../assets/ale.jpg";
import pepePic from "../../assets/giuseppe.jpg";

const About = () => {
  const [validated, setValidated] = useState(false);

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }

    setValidated(true);
  };
  return (
    <div className="pageBody container pt-5">
      <h1><Text tid="about" /></h1>
      <p>
        <Text tid="at" /><strong> Orange Tools</strong>, <Text tid="weHaveVocation" />
        <strong><Text tid="weWorkWithThem" /></strong> 
        <Text tid="toAchieveResults" /><Text tid="weTrustInOur" />
        <strong><Text tid="servicesSystem" /></strong>, <Text tid="servicesSystemDescription" />
        <strong> <Text tid="benefitsForOurCustomers" /></strong>.<br /> 
        <Text tid="weStandOut" />
      </p>
      <div className="team container mb-5 mt-5 text-center">
        <h2 className="display-5 mb-5">Our Team</h2>
        <div className="row">
          <div className="col-lg-3">
            <div className="testimonial-item mx-auto mb-5 mb-lg-0 d-flex flex-column align-items-center justify-content-center">
              <img className="img-fluid w-50 rounded-circle mb-3" src={manuPic} alt="Manu" />
              <h5>
                <a style={{color: "rgb(9, 43, 155)", textDecoration: "none"}} href="https://www.linkedin.com/in/emmartinez-profile/" rel="noreferrer" target="_blank">Manu</a>
              </h5>
              <p className="font-weight-light mb-0">CEO</p>
            </div>
          </div>
          <div className="col-lg-3">
            <div className="testimonial-item mx-auto mb-5 mb-lg-0 d-flex flex-column align-items-center justify-content-center">
              <img className="img-fluid w-50 rounded-circle mb-3" src={alePic} alt="Ale" />
              <h5>
                <a style={{color: "rgb(9, 43, 155)", textDecoration: "none"}} href="https://www.linkedin.com/in/alejandro-planchart-792a84202/" rel="noreferrer" target="_blank">Ale</a>
              </h5>
              <p className="font-weight-light mb-0">Customer Success Manager</p>
            </div>
          </div>
          <div className="col-lg-3">
            <div className="testimonial-item mx-auto mb-5 mb-lg-0 d-flex flex-column align-items-center justify-content-center">
              <img className="img-fluid w-50 rounded-circle mb-3" src={pepePic} alt="Giuseppe" />
              <h5>
                <a style={{color: "rgb(9, 43, 155)", textDecoration: "none"}} href="https://www.linkedin.com/in/giu3337/" rel="noreferrer" target="_blank">Giuseppe</a>
              </h5>
              <p className="font-weight-light mb-0">Sales Manager</p>
            </div>
          </div>
          <div className="col-lg-3">
            <div className="testimonial-item mx-auto mb-5 mb-lg-0 d-flex flex-column align-items-center justify-content-center">
              <img className="img-fluid w-50 rounded-circle mb-3" src={maruPic} alt="Maru" />
              <h5>
                <a style={{color: "rgb(9, 43, 155)", textDecoration: "none"}} href="https://www.linkedin.com/in/mary-martinez92/" rel="noreferrer" target="_blank">Maru</a>
              </h5>
              <p className="font-weight-light mb-0">Campaign Manager</p>
            </div>
          </div>
        </div>
      </div>
      <div className="container pt-5 pb-5">
        <h2><Text tid="letsTalk" /></h2>
        <p>
          <Text tid="letsTalkDescription" />
        </p>
        <Form
          className="mt-5"
          noValidate
          validated={validated}
          onSubmit={handleSubmit}
        >
          <Row className="mb-3">
            <Form.Group as={Col} md="6" controlId="validationCustom01">
              <Form.Label><Text tid="firstName" /></Form.Label>
              <Form.Control
                required
                type="text"
                placeholder=""
                defaultValue="Mark"
              />
              <Form.Control.Feedback><Text tid="looksGood" /></Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} md="6" controlId="validationCustom02">
              <Form.Label><Text tid="lastName" /></Form.Label>
              <Form.Control
                required
                type="text"
                placeholder=""
                defaultValue="Otto"
              />
              <Form.Control.Feedback><Text tid="looksGood" /></Form.Control.Feedback>
            </Form.Group>
          </Row>
          <Row className="mb-3">
            <Form.Group as={Col} md="6" controlId="validationCustom03">
              <Form.Label><Text tid="city" /></Form.Label>
              <Form.Control type="text" placeholder="" required />
              <Form.Control.Feedback type="invalid">
                <Text tid="cityError" />
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} md="3" controlId="validationCustom04">
              <Form.Label><Text tid="state" /></Form.Label>
              <Form.Control type="text" placeholder="" required />
              <Form.Control.Feedback type="invalid">
                <Text tid="stateError" />
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} md="3" controlId="validationCustom05">
              <Form.Label><Text tid="zip" /></Form.Label>
              <Form.Control type="text" placeholder="Zip" required />
              <Form.Control.Feedback type="invalid">
                <Text tid="zipError" />
              </Form.Control.Feedback>
            </Form.Group>
          </Row>
          <Row className="mb-3">
            <Form.Group as={Col} md="6" controlId="validationCustom03">
              <Form.Label><Text tid="company" /></Form.Label>
              <Form.Control type="text" placeholder="" />
            </Form.Group>
          </Row>
          <Row className="mb-3">
            <Form.Group className="mb-3" controlId="validationCustom06">
              <Form.Label><Text tid="message" /></Form.Label>
              <Form.Control
                as="textarea"
                rows={5}
                required
                type="text"
                placeholder=""
              />
              <Form.Control.Feedback><Text tid="looksGood" /></Form.Control.Feedback>
            </Form.Group>
          </Row>
          <Row className="mb-3">
            <Form.Group className="mb-3 d-flex">
              <Form.Check
                required
                feedback="You must agree before submitting."
                className="pe-2"
              />
              <Text tid="agree" />
              <Col className="col-sm-8">
                <Link to="/terms-and-conditions" className="px-1">
                  <Text tid="termsAndConditions" />
                </Link>
              </Col>
            </Form.Group>
          </Row>
          <Button type="submit"><Text tid="submit" /></Button>
        </Form>
      </div>
    </div>
  );
};

export default About;
