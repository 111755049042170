import React from "react";
import bg from "../../assets/bg-full.svg";
import nanLogo from "../../assets/nan.png";
import olapicLogo from "../../assets/olapic.svg";
import globantLogo from "../../assets/globant.svg";
import manuPic from "../../assets/manu.jpg";
import maruPic from "../../assets/maru.jpg";
import alePic from "../../assets/ale.jpg";
import pepePic from "../../assets/giuseppe.jpg";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import Text from "../Text";

import "./Home.css";

const Home = () => {
  return (
    <div className="Home">
      <div className="container-fluid jumbotron-fluid text-center" style={{height: "120vh", background: "linear-gradient(#FF7832, #FFFFFF)"}}>
        <div className="text-center pt-3">
          <h1 className="display-4">
            <Text tid="homeDescription" />
            <br />
            <Text tid="for" />{" "}
            <span style={{fontWeight: '700'}}> 
              <Text tid="bold" />{" "}
            </span>
            <Text tid="companies" />{" "}
            <span style={{fontWeight: '700'}}> 
              <Text tid="boldEs" />
            </span>
          </h1>
        </div>
        <img className="w-2" src={bg} alt="" />
      </div>
      <div className="team container mb-5 mt-5 text-center">
        <h2 className="display-5 mb-5">Our Team</h2>
        <div className="row">
          <div className="col-lg-3">
            <div className="testimonial-item mx-auto mb-5 mb-lg-0 d-flex flex-column align-items-center justify-content-center">
              <img className="img-fluid w-50 rounded-circle mb-3" src={manuPic} alt="Manu" />
              <h5>
                <a style={{color: "rgb(9, 43, 155)", textDecoration: "none"}} href="https://www.linkedin.com/in/emmartinez-profile/" rel="noreferrer" target="_blank">Manu</a>
              </h5>
              <p className="font-weight-light mb-0">CEO</p>
            </div>
          </div>
          <div className="col-lg-3">
            <div className="testimonial-item mx-auto mb-5 mb-lg-0 d-flex flex-column align-items-center justify-content-center">
              <img className="img-fluid w-50 rounded-circle mb-3" src={alePic} alt="Ale" />
              <h5>
                <a style={{color: "rgb(9, 43, 155)", textDecoration: "none"}} href="https://www.linkedin.com/in/alejandro-planchart-792a84202/" rel="noreferrer" target="_blank">Ale</a>
              </h5>
              <p className="font-weight-light mb-0">Customer Success Manager</p>
            </div>
          </div>
          <div className="col-lg-3">
            <div className="testimonial-item mx-auto mb-5 mb-lg-0 d-flex flex-column align-items-center justify-content-center">
              <img className="img-fluid w-50 rounded-circle mb-3" src={pepePic} alt="Giuseppe" />
              <h5>
                <a style={{color: "rgb(9, 43, 155)", textDecoration: "none"}} href="https://www.linkedin.com/in/giu3337/" rel="noreferrer" target="_blank">Giuseppe</a>
              </h5>
              <p className="font-weight-light mb-0">Sales Manager</p>
            </div>
          </div>
          <div className="col-lg-3">
            <div className="testimonial-item mx-auto mb-5 mb-lg-0 d-flex flex-column align-items-center justify-content-center">
              <img className="img-fluid w-50 rounded-circle mb-3" src={maruPic} alt="Maru" />
              <h5>
                <a style={{color: "rgb(9, 43, 155)", textDecoration: "none"}} href="https://www.linkedin.com/in/mary-martinez92/" rel="noreferrer" target="_blank">Maru</a>
              </h5>
              <p className="font-weight-light mb-0">Campaign Manager</p>
            </div>
          </div>
        </div>
      </div>
      <div className="container text-center mt-5 pt-5 mb-5">
        <hr className="featurette-divider" />
        <h2 className="display-5"><Text tid="letsTalk" /></h2>
        <p><Text tid="homeLetsTalkDescription" /></p>
        <Button><Link to="/contact" className="px-1 text-white"><Text tid="contactUs" /></Link></Button>
        <hr className="featurette-divider" />
      </div>
      <div className="container marketing mt-5 mb-5 pb-5">
        <div className="row featurette">
          <h2 className="display-4 text-center mb-5"><Text tid="ourCustomers" />. <span style={{color: "darkgray"}}><Text tid="ourPartners" /></span></h2>
          <div className="d-flex justify-content-around">
           <a href="https://globant.com" className="d-flex justify-content-center align-items-center w-25 h-100" rel="noreferrer" target="_blank">
              <img  src={globantLogo} alt="" />
            </a>
            <a href="https://olapic.com" className="d-flex justify-content-center align-items-center w-25 h-100"  rel="noreferrer" target="_blank">
              <img src={olapicLogo} className="w-75" alt="" />
            </a>
            <a href="https://nantech.com.ar" className="d-flex justify-content-center align-items-center w-25 h-100" rel="noreferrer" target="_blank">
              <img src={nanLogo} alt="" />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
