import React from 'react'

import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import Text from "../Text";

import services from './constants/services';

import "./Services.css";

const Services = props => {
  return (
    <div className="Services">
      <div className="pageBody container pt-5">
        <h1><Text tid="services" /></h1>
        <p>
          <Text tid="at" /><strong> Orange Tools</strong>, <Text tid="weHaveVocation" />
          <strong><Text tid="weWorkWithThem" /></strong> 
          <Text tid="toAchieveResults" /><Text tid="weTrustInOur" />
          <strong><Text tid="servicesSystem" /></strong>, <Text tid="servicesSystemDescription" />
          <strong> <Text tid="benefitsForOurCustomers" /></strong>.<br /> 
          <Text tid="weStandOut" />
        </p>
        <div className="list">
          <div className="container marketing">
            {
              services.map((service, index) => (
                <>
                  {index % 2 !== 0 ?
                    <div className="row featurette">
                      <div className="col-md-7">
                        <h2 className="featurette-heading">
                          <Text tid={service.tid} />.{" "}
                          <span className="text-muted"><Text tid={service.muted} />.</span>
                        </h2>
                        <p className="lead">
                          <Text tid={service.leadTid} />.
                          <br />
                          <Text tid={service.descriptionTid} />
                        </p>
                      </div>
                      <div className="featurette-image col-md-5">
                        <img src={service.image} alt={service.id} />
                      </div>
                    </div> : 
                    <div className="row featurette">
                      <div className="featurette-image col-md-5">
                        <img src={service.image} alt={service.id} />
                      </div>
                      <div className="col-md-7">
                        <h2 className="featurette-heading">
                          <Text tid={service.tid} />.{" "}
                          <span className="text-muted"><Text tid={service.muted} />.</span>
                        </h2>
                        <p className="lead">
                          <Text tid={service.leadTid} />.
                          <br />
                          <Text tid={service.descriptionTid} />
                        </p>
                      </div>
                    </div>
                  }
                  <hr className="featurette-divider" />
                </>
              ))
            }
          </div>
        </div>
      </div>
      <div className="container text-center mt-5 pt-5 mb-5">
        <h2 className="display-5"><Text tid="letsTalk" /></h2>
        <p><Text tid="homeLetsTalkDescription" /></p>
        <Button><Link to="/contact" className="px-1 text-white"><Text tid="contactUs" /></Link></Button>
        <hr className="featurette-divider" />
      </div>
    </div>
  )
}

export default Services;
