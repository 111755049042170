import React, { useContext, useEffect } from "react";
import { languageOptions } from "../../languages";
import { LanguageContext } from "../Language/LanguageContext";
import { Navbar, Container, Nav } from "react-bootstrap";
import Text from "../Text";
import logo from "../../assets/logo.png";

const NavBar = () => {
  /**
   * The Language context to change the language.
   * @type {Object}
   */
  const { userLanguageChange } = useContext(LanguageContext);
  /**
   * Set selected language by calling context method.
   *
   * @param {event} event  The select language event.
   * @param {event} lang   The selected language.
   */
  const handleLanguageChange = (event) => {
    return userLanguageChange(event.target.value);
  };

  /**
   * Set the default language from localStorage. If no data, use the user browser language.
   */
  useEffect(() => {
    let defaultLanguage = "en";
    userLanguageChange(defaultLanguage);
  }, [userLanguageChange]);

  return (
    <Navbar bg="primary" expand="lg" variant="dark">
      <Container>
        <Navbar.Brand href="/" alt="OrangeToolsUSA logo">
          <img
            src={logo}
            width="30"
            height="30"
            className="d-inline-block align-top"
            alt="OrangeToolsUSA logo"
          />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link href="/" alt="Home">
              <Text tid="home" />
            </Nav.Link>
            <Nav.Link href="/services" alt="Services">
              <Text tid="services" />
            </Nav.Link>
            <Nav.Link href="/about" alt="About">
              <Text tid="about" />
            </Nav.Link>
            <Nav.Link href="/contact" alt="Contact">
              <Text tid="contactUs" />
            </Nav.Link>
          </Nav>
          <Nav>
            {Object.entries(languageOptions).map(([id, name]) => (
              <Nav.Link href="#" key={id} alt={`select ${name}`}>
                <button
                  alt={name}
                  className={`btn flag-icon ${
                    id === "en" ? "flag-icon-us" : "flag-icon-es"
                  } m-1`}
                  onClick={handleLanguageChange}
                  value={id}
                ></button>
              </Nav.Link>
            ))}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default NavBar;
