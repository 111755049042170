import React from "react";
import logo from "../../assets/logo.png";
import footerLinks from './constants/links';

import { Link } from "react-router-dom";
import Text from "../Text";

const Footer = () => {
  return (
    <footer className="footer mt-auto pb-3 bg-dark sticky-bottom">
      <div className="d-flex justify-content-center align-items-center">
        <div className="col-2 pt-3 text-center">
          <img src={logo} alt="" style={{width: '30px'}}/>
          <div className="d-flex justify-content-center align-items-center">
            <h5 className="fs-5 mt-1 text-center text-white">Orange Tools</h5>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row py-3">
          <div className="col-12 col-md pb-3 text-white text-center">
            <small className="h6">
              <Link 
                to="/home" 
                className="text-white text-center"
                style={{ textDecoration: 'none' }}
              >Orange Tools LLC</Link>
            </small>
            <small className="d-block text-muted">30 N Gould St Ste N</small>
            <small className="d-block text-muted">Sheridan, WY 82801</small>
            <small className="d-block text-muted">
              Northwest Registered Agent Service, Inc.
            </small>
            <small className="d-block text-muted">
              info@orangetoolsusa.com
            </small>
            <small className="d-block text-muted">(307) 217-8032</small>
            <small className="d-block text-muted">(307) 459-0144</small>
          </div>
          <div className="col-6 col-md text-white text-center">
            <small className="h6">Resources</small>
            <ul className="list-unstyled text-small">
              {
                footerLinks.about.map((link) => {
                  return (
                    <li className="" key={link.key}>
                      <a
                        className="link-secondary text-decoration-none"
                        href={link.url}
                      ><Text tid={link.tid} /></a>
                    </li>
                  )
                })
              }
            </ul>
          </div>
          <div className="col-6 col-md text-white text-center">
            <small className="h6">Follow Us</small>
            <ul className="list-unstyled text-small">
            {
                footerLinks.services.map((link) => {
                  return (
                    <li className="" key={link.key}>
                      <a
                        className="link-secondary text-decoration-none"
                        href={link.url}
                      ><Text tid={link.tid} /></a>
                    </li>
                  )
                })
              }
            </ul>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
