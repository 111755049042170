const footerLinks = {
  services: [
    {
      url: 'https://twitter.com/orangetoolsusa',
      label: 'Twitter',
      key: 'twitter',
      tid: 'twitter',
    },
    {
      url: 'https://instagram.com/orangetoolsok',
      label: 'Instagram',
      key: 'instagram',
      tid: 'instagram',
    },
    {
      url: 'mailto:emmartinez@orangetoolsusa.com',
      label: 'Email',
      key: 'email',
      tid: 'email',
    },
    {
      url: 'https://www.linkedin.com/company/orangetoolsusa',
      label: 'LinkedIn',
      key: 'linkedin',
      tid: 'linkedin',
    },
  ],
  about: [
    {
      url: '/about',
      label: 'About Us',
      key: 'about',
      tid: 'about',
    },
    {
      url: '/services',
      label: 'Services',
      key: 'services',
      tid: 'services',
    },
    {
      url: '/privacy',
      label: 'Privacy',
      key: 'privacy',
      tid: 'privacy',
    },
    {
      url: '/terms',
      label: 'Terms',
      key: 'terms',
      tid: 'terms',
    }
  ],
  features: [],
}

export default footerLinks;
