import es from "./es.json";
import en from "./en.json";

/**
 * The Language dictionary to use translations from LanguageContext.
 *
 * @type {Object}
 */
export const dictionaryList = { es, en };

/**
 * The Language options to show in the Language Selector.
 *
 * @type {Object}
 */
export const languageOptions = {
  es: "Lenguaje: Español",
  en: "Language: English",
};
