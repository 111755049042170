import React, { useState } from "react";
import { Form, Row, Button, Col } from "react-bootstrap";
import { Link } from "react-router-dom";

const Contact = () => {
  const [validated, setValidated] = useState(false);

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }

    console.log(form.firstname.value, form.lastname.value)

    setValidated(true);
  };

  return (
    <div className="pageBody container py-5">
      <h1>Contact Us</h1>
      <p>
        We are ready to work with you! Please fill the form below and we will
        get in touch as soon as possible to start working on your projects!
      </p>
      <Form
        className="mt-5"
        noValidate
        validated={validated}
        onSubmit={handleSubmit}
      >
        <Row className="mb-3">
          <Form.Group as={Col} md="6" controlId="validationCustom01">
            <Form.Label>First name</Form.Label>
            <Form.Control
              required
              type="text"
              placeholder="First name"
              name="firstname"
              defaultValue="Mark"
            />
            <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
          </Form.Group>
          <Form.Group as={Col} md="6" controlId="validationCustom02">
            <Form.Label>Last name</Form.Label>
            <Form.Control
              required
              type="text"
              placeholder="Last name"
              name="lastname"
              defaultValue="Otto"
            />
            <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
          </Form.Group>
        </Row>
        <Row className="mb-3">
          <Form.Group as={Col} md="6" controlId="validationCustom03">
            <Form.Label>City</Form.Label>
            <Form.Control type="text" placeholder="City" required />
            <Form.Control.Feedback type="invalid">
              Please provide a valid city.
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group as={Col} md="3" controlId="validationCustom04">
            <Form.Label>State</Form.Label>
            <Form.Control type="text" placeholder="State" required />
            <Form.Control.Feedback type="invalid">
              Please provide a valid state.
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group as={Col} md="3" controlId="validationCustom05">
            <Form.Label>Zip</Form.Label>
            <Form.Control type="text" placeholder="Zip" required />
            <Form.Control.Feedback type="invalid">
              Please provide a valid zip.
            </Form.Control.Feedback>
          </Form.Group>
        </Row>
        <Row className="mb-3">
          <Form.Group as={Col} md="6" controlId="validationCustom03">
            <Form.Label>Company</Form.Label>
            <Form.Control type="text" placeholder="Company" />
          </Form.Group>
        </Row>
        <Row className="mb-3">
          <Form.Group className="mb-3" controlId="validationCustom06">
            <Form.Label>Message</Form.Label>
            <Form.Control
              as="textarea"
              rows={5}
              required
              type="text"
              placeholder="Tell us more details about your project or contact reason..."
            />
            <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
          </Form.Group>
        </Row>
        <Row className="mb-3">
          <Form.Group className="mb-3 d-flex">
            <Form.Check
              required
              feedback="You must agree before submitting."
              className="pe-2"
            />
            Agree to
            <Col className="col-sm-8">
              <Link to="/terms-and-conditions" className="px-1">
                Terms and Conditions
              </Link>
            </Col>
          </Form.Group>
        </Row>
        <Button type="submit">Submit</Button>
      </Form>
    </div>
  );
};

export default Contact;
